import { defineMessages } from 'react-intl'

export default defineMessages({
  assignToUser: {
    id: 'app.containers.RoleManagementPage.assignToUser',
    defaultMessage: 'Assign to User',
  },
  assignRoleToUser: {
    id: 'app.containers.RoleManagementPage.assignRoleToUser',
    defaultMessage: 'Assign Role to User',
  },
  assignmentFormError: {
    id: 'app.containers.RoleManagementPage.assignmentFormError',
    defaultMessage: 'Please enter all required fields',
  },
  cancel: {
    id: 'app.containers.RoleManagementPage.cancel',
    defaultMessage: 'Cancel',
  },
  createRole: {
    id: 'app.containers.RoleManagementPage.createRole',
    defaultMessage: 'Create Role',
  },
  description: {
    id: 'app.containers.RoleManagementPage.description',
    defaultMessage: 'Description',
  },
  errorOccurred: {
    id: 'app.containers.RoleManagementPage.errorOccurred',
    defaultMessage: 'An error occurred',
  },
  name: {
    id: 'app.containers.RoleManagementPage.name',
    defaultMessage: 'Name',
  },
  numberOfUsers: {
    id: 'app.containers.RoleManagementPage.numberOfUsers',
    defaultMessage: 'Number Of Users',
  },
  permissions: {
    id: 'app.containers.RoleManagementPage.permissions',
    defaultMessage: 'Permissions',
  },
  roleDetails: {
    id: 'app.containers.RoleManagementPage.roleDetails',
    defaultMessage: 'Role Details',
  },
  roles: {
    id: 'app.containers.RoleManagementPage.roles',
    defaultMessage: 'Roles',
  },
  save: {
    id: 'app.containers.RoleManagementPage.save',
    defaultMessage: 'Save',
  },
  scope: {
    id: 'app.containers.RoleManagementPage.scope',
    defaultMessage: 'Scope',
  },
  selectAll: {
    id: 'app.containers.RoleManagementPage.selectAll',
    defaultMessage: 'Select All',
  },
  unassign: {
    id: 'app.containers.RoleManagementPage.unassign',
    defaultMessage: 'Unassign',
  },
  unassignRole: {
    id: 'app.containers.RoleManagementPage.unassignRole',
    defaultMessage: 'Unassign Role',
  },
  unselectAll: {
    id: 'app.containers.RoleManagementPage.unselectAll',
    defaultMessage: 'Unselect All',
  },
  user: {
    id: 'app.containers.RoleManagementPage.user',
    defaultMessage: 'User',
  },
  users: {
    id: 'app.containers.RoleManagementPage.users',
    defaultMessage: 'Users',
  },
  usersAssignmentPreamble: {
    id: 'app.containers.RoleManagementPage.users',
    defaultMessage: 'The following users have been assigned this role',
  },
})
