import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import qs from 'query-string'
import { push } from 'connected-react-router'
import { fromJS } from 'immutable'

import permissionChecker from 'containers/PermissionChecker'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import SectionHeader from 'components/SectionHeader'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import { isPlatformRole } from 'utils/sonraiUtils'
import { getAllRoles } from 'containers/SonraiData/actions'
import { selectAllRoles } from 'containers/SonraiData/selectors'

import messages from './messages'

import './styles.css'

export class RoleManagementPage extends Component {
  constructor(props, context) {
    super(props, context)
    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        padding: '1rem',
        width: '100%',
        height: '100%',
      },
      createButtonContiner: {
        textAlign: 'right',
      },
      headerContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto 200px',
      },
    }
  }

  handleNavToBotDetail = ({ srn }) => {
    this.props.push({
      pathname: '/App/RoleManagement/RoleDetails',
      search: qs.stringify({
        roleId: srn,
      }),
    })
  }

  renderHeader() {
    return [
      <div key={'header-text'}>
        <SectionHeader>
          <DynamicFormattedMessage {...messages.roles} />
        </SectionHeader>
      </div>,
    ]
  }

  navToRoleDetails = ({ srn }) => {
    this.props.push({
      pathname: '/App/RoleManagement/RoleDetails',
      search: qs.stringify({
        roleId: srn,
      }),
    })
  }

  renderBody() {
    if (this.props.allRoles.get('loading')) {
      return (
        <BorderedCard>
          <SquareLoadingAnimation />
        </BorderedCard>
      )
    }

    if (this.props.allRoles.get('error')) {
      return (
        <BorderedCard>
          <DynamicFormattedMessage {...messages.errorOccurred} />
        </BorderedCard>
      )
    }

    const data = this.props.allRoles
      .get('data')
      .toJS()
      .map(row => ({
        name: row.name,
        type: isPlatformRole(fromJS(row)) ? 'Platform' : 'Swimlane',
        users: row.users,
        expandedPermissions: row.expandedPermissions,
        description: row.description,
        srn: row.srn,
        roleAssignments: row.roleAssignments,
        permissions: row.permissions,
        resourceId: row.resourceId,
      }))

    return (
      <BorderedCard>
        <div>
          <DataTable
            noTableActions
            data={data}
            customGridProps={{ domLayout: 'autoHeight' }}
            autosize={false}
            onDoubleClickRow={this.navToRoleDetails}
            customColumnConfig={{
              srn: { hide: true },
              roleAssignments: { hide: true },
              permissions: { hide: true },
              users: {
                hide: !this.props.userHasPermission({
                  permissionName: 'edit.roleassignments',
                }),
              },
              expandedPermissions: {
                headerValueGetter: () => 'Permissions',
                cellRendererFramework: params => (
                  <span>{params.value.length}</span>
                ),
              },
              resourceId: { hide: true },
              description: { flex: 1 },
            }}
          />
        </div>
      </BorderedCard>
    )
  }

  render() {
    return (
      <div className="role-managment-page" style={this.styles.container}>
        <div>
          <div
            className="role-overview-header"
            style={this.styles.headerContainer}
          >
            {this.renderHeader()}
          </div>
          <div className="role-overview-body">{this.renderBody()}</div>
        </div>
      </div>
    )
  }
}

RoleManagementPage.propTypes = {
  // ~ bound action creators ~
  getAllRoles: PropTypes.func.isRequired,
  push: PropTypes.func,

  // ~ props from redux store ~
  allRoles: ImmutablePropTypes.map,

  userHasPermission: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  allRoles: selectAllRoles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllRoles,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(RoleManagementPage)
